<script>
export default {
	props: {
		order: Object,
		formData: Object,
		paymentSubmitData: Object,
		transactionHash: String,
	},

	mounted() {
		document.getElementById('first-data-form')?.remove()
		const { inputs, action } = this.paymentSubmitData

		const form = document.createElement('form')
		form.setAttribute('id', 'first-data-form')
		form.setAttribute('action', action)
		form.setAttribute('method', 'post')
		form.style.display = 'none'

		Object.assign(inputs, {
			cardnumber: this.formData.number,
			expmonth: this.formData.expirationMonth,
			expyear: this.formData.expirationYear,
			cvm: this.formData.cvv,
		})

		for (var name in inputs) {
			var input = document.createElement('input')
			input.setAttribute('name', name)
			input.setAttribute('value', inputs[name])
			input.setAttribute('type', 'hidden')
			form.appendChild(input)
		}

		// this.checkout.stepSubmit()
		document.body.appendChild(form)
		form.submit()
	},
}
</script>

<template>
	<div></div>
</template>
